import Collections from "./Collections"

const Collectionmain=()=>{
    return(
        <>
        <Collections/>
        </>
    )
}

export default Collectionmain;